<template>
	<div class="pd20">
		<div class="ft20 cl-black cl-main ftw500">万能表单</div>
		<div class="mt20">
			<div class="pd20 bg-w">
				<div>
					<a-button type="primary" icon="plus" @click="AddAct()">添加表单</a-button>
				</div>
		
				<div class="mt20">
          <a-form layout="inline">
            <a-form-item label="">
              <a-input v-model="search.name" placeholder="请输入内容名称"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button @click="searchAct" type="primary">查询</a-button>
              <a-button @click="cancelAct" class="ml10">取消</a-button>
            </a-form-item>
          </a-form>
				</div>
		
				<div class="mt20">
					
				<div class="wxb-table-gray">
						<a-table rowKey="look_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">

							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="EditAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											  </a-menu-item>
                      <a-menu-item>
                        <a class="menu-act" href="javascript:;" @click="AnswerAct(record)">
                          <i class="iconfont ft14 iconhexiao1"></i>
                          <span class="ml10">答题</span>
                        </a>
                      </a-menu-item>
											  <a-menu-item  >
												<a class="menu-act" href="javascript:;"@click="delAct(record)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除</span>
												</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading:false,
				search: {
					name: ''
				},
				columns: [
					{title: '表单id',dataIndex: 'form_id',align: 'left',ellipsis: true},
					{title: '表单名称',dataIndex: 'name',align: 'left',ellipsis: true},
					{title: '标识符',dataIndex: 'identifier',align: 'left',ellipsis: true},
					// {title: '作用域',dataIndex: 'scope',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'left',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {

			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getFormList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					name: this.search.name,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			
			delAct(record){
				this.$confirm({
					title:'确认删除这个表单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delForm',{
								form_id:record.form_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			AddAct() {
				this.$router.push('/form/edit');
			},
      AnswerAct(record){
        this.$router.push('/form/answer?id='+record.form_id);
      },
			EditAct(record) {
				this.$router.push('/form/edit?id='+record.form_id);
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	
</style>
